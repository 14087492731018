/** @jsx jsx */
import { Fragment, FC } from 'react'
import { jsx } from '@emotion/core'
import Img from 'gatsby-image/withIEPolyfill'

import Helmet from '../../../components/HelmetHelper'
import Container from '../../../components/Container'
import Text, { TEXT_SIZE_SMALL } from '../../../components/content/Text'
import { THEME_CITRON } from '../../../constants'
import Slide, { SLIDE_LAYOUT_MOCK_FULL_SCREEN } from '../../../components/Slide'
import {
	LayoutSplitHeading,
	LayoutIndented,
	LayoutVerticalCadence,
	LAYOUT_V_CADENCE_SIZE_MEDIUM,
	LAYOUT_V_CADENCE_SIZE_LARGE,
	LAYOUT_V_CADENCE_SIZE_EXTRA_SMALL,
} from '../../../components/content/Layout'
import { provideAccentTheme } from '../../../context/AccentThemeContext'
import { CopyButtonInline } from '../../../components/CopyButton'
import { graphql, useStaticQuery } from 'gatsby'
import RegisterForm from './RegisterForm'

type Props = {
	accentTheme: string
}

const EthicsPage: FC<Props> = ({ accentTheme }) => {
	const data = useStaticQuery(graphql`
		query {
			vincent: file(relativePath: { eq: "design-ethics-vincent.jpg" }) {
				...ImageFluidFull
			}
		}
	`)

	return (
		<Fragment>
			<Helmet>
				<title>Vincent Hofmann on Design Ethics</title>
				<meta content="Vincent Hofmann on Design Ethics" property="og:title" />
				<meta content="Vincent Hofmann on Design Ethics" name="twitter:title" />
				<meta
					name="description"
					content="A masterclass on the ethics and responsibility of design."
				/>
				<meta
					property="og:description"
					content="A masterclass on the ethics and responsibility of design."
				/>
				<meta
					content="https://freeassociation.com/share/ethics-share.jpg"
					property="og:image"
				/>
				<meta
					content="https://freeassociation.com/share/ethics-share.jpg"
					property="twitter:image:src"
				/>
			</Helmet>
			<Slide layout={SLIDE_LAYOUT_MOCK_FULL_SCREEN}>
				<Container>
					<LayoutIndented>
						<Text>
							<p className="--eyebrow">FA Academy</p>
							<h1 className="--headline">Vincent Hofmann on Design Ethics</h1>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_LARGE}>
						<LayoutIndented>
							<Img fluid={data.vincent.childImageSharp.fluid} />
						</LayoutIndented>
						<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
							<LayoutSplitHeading title="About Us">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p>
										Free Association is a design agency focused on digital
										products and brand systems. We’ve built longstanding
										partnerships with companies like Google, Facebook, Toyota,
										eBay, PayPal, Waze and a range of new ventures. We pursue
										meaningful work and surround ourselves with talented,
										inspiring people.
									</p>
								</Text>
							</LayoutSplitHeading>
							<LayoutSplitHeading title="Syllabus">
								<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_EXTRA_SMALL}>
									<Text defaultParagraph={TEXT_SIZE_SMALL}>
										<h4>1. Great Works of the 21st Century</h4>
										<p>
											Making the case that designers have a responsibility to be
											change-makers.
										</p>
										<p>
											<cite>Cited works by: Thomas Berry</cite>
										</p>
									</Text>
									<Text defaultParagraph={TEXT_SIZE_SMALL}>
										<h4>2. Zooming In and Out</h4>
										<p>
											Rooting our day to day design work in a greater societal
											context.
										</p>
										<p>
											<cite>
												Cited works by: Ray Eames, Victor Papanek, Mike
												Monteiro, and John Calco
											</cite>
										</p>
									</Text>
									<Text defaultParagraph={TEXT_SIZE_SMALL}>
										<h4>3. Design Coming of Age</h4>
										<p>
											Changing the expectation of what designers should be good
											at.
										</p>
										<p>
											<cite>
												Cited works by: Anne Marie Willis, Batya Friedman,
												Peter-Paul Verbeek, Cennydd Bowles, Jared Spool, and Don
												Norman
											</cite>
										</p>
									</Text>
									<Text defaultParagraph={TEXT_SIZE_SMALL}>
										<h4>4. Ethical Patterns and Evaluation</h4>
										<p>
											Tactical strategies for making ethical design decisions.
										</p>
										<p>
											<cite>
												Cited works by: Baumer-Silberman, Donella Meadows, Data
												& Society, Koos Design, Ethical Design Thinking, Joe
												Edelman, Disrupt Design, RSA Blueprint for GoodWork,
												Markkula Center for Applied Ethics, Dave Snowden, Sonja
												Blignaut, Society Centered Design
											</cite>
										</p>
									</Text>
									<Text defaultParagraph={TEXT_SIZE_SMALL}>
										<h4>5. Enduring Ethics</h4>
										<p>Final thoughts on putting theory into practice.</p>
										<p>
											<cite>
												Cited works by: Donella Meadows, Emanuel Moss, and Jacob
												Metcalf
											</cite>
										</p>
									</Text>
								</LayoutVerticalCadence>
							</LayoutSplitHeading>
							<LayoutSplitHeading title="About Vincent">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p>
										Vincent Hofmann is an employee experience and technology
										designer. He works to make work, work, for everyone. Vincent
										is the co-founder of Inquisition, SiGNL and The GoodWork
										Society and a fellow at The Royal Society for the
										encouragement of Arts, Manufactures and Commerce.
									</p>
								</Text>
							</LayoutSplitHeading>
							<LayoutSplitHeading title="">
								<RegisterForm />
							</LayoutSplitHeading>
						</LayoutVerticalCadence>
					</LayoutVerticalCadence>
				</Container>
			</Slide>
			<Slide prefferedTheme={accentTheme}>
				<Container>
					<LayoutIndented>
						<Text>
							<p className="--headline">Let’s work together.</p>
							<p className="--small">
								<CopyButtonInline
									copiedMessage="Copied"
									copyValue="hello@freeassociation.com"
								>
									hello@freeassociation.com
								</CopyButtonInline>
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
		</Fragment>
	)
}

export default provideAccentTheme(EthicsPage, THEME_CITRON)
